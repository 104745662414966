<template>
    <div class='container'>
        <HeaderNav ref="headerNav" />
        <div class="dialogBox" v-if="dialogFlag">
            <div class="dialogInner">
                <div class="dialogT1">恭喜您完善成功</div>
                <div class="dialogT2">请妥善保管您的账户信息</div>
                <div class="dialogT3"></div>
                <div class="dialogT4" @click="goIndex">{{backCount}}s后返回首页</div>
            </div>
        </div>
        <div class="inner">
            <div class="logo"></div>
            <div class="form">
                <div class="formTit">账号信息</div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.account.required">*</span>
                            <span class="labelText">{{formOpt.account.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input type="text" v-model="formOpt.account.val"
                            :placeholder="formOpt.account.placeholderText" />
                    </div>
                    <div class="form-warn" v-if="!formOpt.account.canPass">必填</div>
                </div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.password.required">*</span>
                            <span>{{formOpt.password.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input v-model="formOpt.password.val" :placeholder="formOpt.password.placeholderText"
                            :type="formOpt.password.type" @input="passCheck" />
                        <div class="passDeg" v-if="passwordLv!=0">
                            <span>密码强度：</span>
                            <span class="passP weakP" v-if="passwordLv==1">弱</span>
                            <span class="passP midP" v-if="passwordLv==2">中</span>
                            <span class="passP strongP" v-if="passwordLv>2">强</span>
                        </div>
                    </div>
                    <div class="form-warn" v-if="!formOpt.password.canPass">必填</div>
                </div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.passwordCheck.required">*</span>
                            <span>{{formOpt.passwordCheck.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input :type="formOpt.password.type" v-model="formOpt.passwordCheck.val"
                            :placeholder="formOpt.passwordCheck.placeholderText" />
                    </div>
                    <div class="form-warn" v-if="formOpt.passwordCheck.val!=formOpt.password.val">密码不一致</div>
                </div>
                <div class="formTit">联系人信息</div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.corporate.required">*</span>
                            <span class="labelText">{{formOpt.corporate.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input type="text" v-model="formOpt.corporate.val"
                            :placeholder="formOpt.corporate.placeholderText" />
                    </div>
                    <div class="form-warn" v-if="!formOpt.corporate.canPass">必填</div>
                </div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.name.required">*</span>
                            <span>{{formOpt.name.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input type="text" v-model="formOpt.name.val" :placeholder="formOpt.name.placeholderText" />
                    </div>
                    <div class="form-warn" v-if="!formOpt.name.canPass">必填</div>
                </div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.landline.required">*</span>
                            <span>{{formOpt.landline.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input type="text" v-model="formOpt.landline.val"
                            :placeholder="formOpt.landline.placeholderText" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.email.required">*</span>
                            <span>{{formOpt.email.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input type="text" v-model="formOpt.email.val" :placeholder="formOpt.email.placeholderText" />
                    </div>
                    <div class="form-warn" v-if="!formOpt.email.canPass">必填</div>
                </div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.phone.required">*</span>
                            <span>{{formOpt.phone.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input type="text" v-model="formOpt.phone.val" :placeholder="formOpt.phone.placeholderText"
                            style="width:233px;margin-right:11px" maxlength="11" />
                        <span class="sendCode" @click="btn_send">{{sendBtn}}</span>
                    </div>
                    <div class="form-warn" v-if="!formOpt.phone.canPass">必填</div>
                </div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.verificationCode.required">*</span>
                            <span>{{formOpt.verificationCode.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input type="text" v-model="formOpt.verificationCode.val"
                            :placeholder="formOpt.verificationCode.placeholderText" />
                    </div>
                    <div class="form-warn" v-if="!formOpt.verificationCode.canPass">必填</div>
                </div>
                <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.keyWordName.required">*</span>
                            <span>{{formOpt.keyWordName.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <input type="text" v-model="formOpt.keyWordName.val"
                            :placeholder="formOpt.keyWordName.placeholderText" />
                        <div class="keywordTip">请填写您公司产品名称和关注项目名称或项目类型“;”隔开</div>
                        <div class="signTip" @click="viewRule">注册默认您同意<span>《用户服务协议》</span></div>
                    </div>
                </div>
                <!-- <div class="form-row">
                    <div class="form-key">
                        <div class="form-label">
                            <span class="labelStar" v-if="formOpt.region.required">*</span>
                            <span>{{formOpt.region.label}}</span>
                        </div>
                    </div>
                    <div class="form-val">
                        <select name="" id="" v-model="formOpt.region.val">
                            <option value="">请选择</option>
                            <option :value="item.label" v-for="item in areas" :key="item.value">{{item.label}}</option>
                        </select>

                    </div>
                    <div class="form-warn" v-if="!formOpt.region.canPass">必填</div>
                </div> -->
                <div class="signBtn" @click="btn_submit">
                    注册
                </div>
                <div class="loginTip" @click="callLoginBox">已有账号，立即登录</div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
    .container {
        position: relative;
        padding-top: 0px;
        padding-bottom: 70px;

        .dialogBox {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: fixed;
            z-index: 10;
            background: rgba(0, 0, 0, 0.5);

            .dialogInner {
                width: 500px;
                height: 450px;
                padding-top: 69px;
                padding-bottom: 47px;
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url("../assets/img/dialogBg.png");
                background-repeat: no-repeat;
                background-size: cover;

                .dialogT1 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                    text-align: center;
                    color: #ffffff;
                    line-height: 28px;
                    margin-bottom: 15px;
                }

                .dialogT2 {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    color: #ffffff;
                    line-height: 20px;
                    margin-bottom: 24px;
                }

                .dialogT3 {
                    background-image: url("../assets/img/dialogCheck.png");
                    background-repeat: no-repeat;
                    width: 140px;
                    height: 140px;
                    margin: 0 auto;
                    margin-bottom: 67px;
                    background-size: cover;
                }

                .dialogT4 {
                    margin: 0 auto;
                    width: 270px;
                    height: 40px;
                    background: #ffffff;
                    border-radius: 20px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: #266ddc;
                    line-height: 40px;
                }
            }
        }

        .inner {
            width: 1000px;
            box-sizing: border-box;
            padding: 38px 247px;
            background-color: #fff;
            border-radius: 20px;
            box-shadow: 6px 10px 17px 0px rgba(1, 69, 133, 0.21);
            margin: 0 auto;

            .logo {
                width: 195px;
                height: 59px;
                margin: 0 auto;
                background-image: url("../assets/img/PNG3.png");
                background-size: cover;
                margin-bottom: 30px;
            }

            .form {
                overflow: hidden;

                .formTit {
                    font-size: 16px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    text-align: left;
                    color: #266ddc;
                    line-height: 22px;
                    margin-bottom: 19px;
                }

                .form-row {
                    display: flex;
                    margin-bottom: 20px;

                    .form-key {
                        width: 64px;
                        height: 32px;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        line-height: 32px;
                        margin-right: 16px;

                        .labelStar {
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            color: #d92e26;
                            line-height: 22px;
                        }

                        .labelText {
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            line-height: 22px;
                        }
                    }

                    .form-val {
                        input {
                            width: 326px;
                            height: 32px;
                            box-sizing: border-box;
                            padding-left: 12px;
                            font-size: 14px;
                            background: #ffffff;
                            border: 1px solid #dddddd;
                            border-radius: 4px;
                            color: #999999;
                        }

                        select {
                            width: 326px;
                            height: 32px;
                            box-sizing: border-box;
                            padding-left: 12px;
                            font-size: 14px;
                            background: #ffffff;
                            border: 1px solid #dddddd;
                            border-radius: 4px;
                            color: #999999;
                            outline: none;
                        }

                        .sendCode {
                            display: inline-block;
                            width: 80px;
                            text-align: center;
                            color: #266ddc;
                            font-size: 14px;
                            cursor: pointer;
                        }

                        .sendCode:hover {
                            text-decoration: underline;
                        }

                        .passDeg {
                            margin: 10px;
                            height: 17px;
                            display: flex;
                            align-items: center;
                            font-size: 14px;

                            .passP {
                                display: block;
                                color: #fff;
                                width: 44px;
                                height: 12px;
                                padding: 2px 0;
                                font-size: 10px;
                                text-align: center;
                                line-height: 12px;
                                margin-right: 2px;
                            }

                            .weakP {
                                background-color: #DF766F;
                            }

                            .midP {
                                background-color: #EEB559;
                            }

                            .strongP {
                                background-color: #cccccc;
                            }
                        }

                        .keywordTip {
                            margin-top: 10px;
                            font-size: 11px;
                            font-weight: 400;
                            text-align: left;
                            color: #666666;
                            line-height: 22px;
                        }

                        .signTip {
                            margin-top: 14px;
                            font-size: 14px;
                            color: #666666;

                            span {
                                color: #266ddc;
                                cursor: pointer;
                            }

                            span:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .form-warn {
                        height: 32px;
                        line-height: 32px;
                        color: #d92e26;
                        font-size: 14px;
                        margin-left: 2px;
                    }
                }

                .signBtn {

                    width: 270px;
                    height: 40px;
                    margin: 0 auto;
                    text-align: center;
                    line-height: 40px;
                    color: white;
                    background: linear-gradient(90deg, #4097ec, #3d81ea);
                    border-radius: 20px;
                    font-size: 18px;
                    margin-top: 40px;
                    margin-bottom: 14px;
                    cursor: pointer;
                }

                .loginTip {
                    font-size: 14px;
                    text-align: center;
                    color: #266ddc;
                    cursor: pointer;
                }

                .loginTip:hover {
                    text-decoration: underline;
                }

            }

        }
    }
</style>
<script>
    import HeaderNav from "@/components/HeaderNav"
    import areas from "@/utils/area"
    import {
        register,
        resetPass,
        setPass,
        updateInfo,
        sendCode
    } from "@/request/user"
    export default {
        name: 'Sign',

        props: {

        },
        data() {
            return {
                passwordLv: 0,
                sendBtn: "发送验证码",
                interval: undefined,
                formOpt: {
                    account: {
                        label: "用户账号",
                        type: "input",
                        required: true,
                        maxlength: 20,
                        placeholderText: "请输入用户账号",
                        canPass: true,
                        val: ""
                    },
                    password: {
                        label: "输入密码",
                        type: "password",
                        required: true,
                        maxlength: 20,
                        placeholderText: "请输入密码",
                        canPass: true,
                        val: ""
                    },
                    passwordCheck: {
                        label: "确认密码",
                        type: "password",
                        required: true,
                        maxlength: 20,
                        placeholderText: "请再次输入密码",
                        canPass: true,
                        val: ""
                    },
                    corporate: {
                        label: "公司名称",
                        type: "input",
                        required: true,
                        maxlength: 20,
                        placeholderText: "请输入公司名称",
                        canPass: true,
                        val: ""
                    },
                    name: {
                        label: "联系人",
                        type: "input",
                        required: true,
                        maxlength: 20,
                        placeholderText: "请输入联系人",
                        canPass: true,
                        val: ""
                    },
                    landline: {
                        label: "固定电话",
                        type: "input",
                        required: false,
                        maxlength: 11,
                        placeholderText: "请输入固定电话",
                        val: ""
                    },
                    email: {
                        label: "邮箱",
                        type: "input",
                        required: true,
                        maxlength: 20,
                        placeholderText: "请输入邮箱",
                        canPass: true,
                        val: ""
                    },
                    phone: {
                        label: "手机号",
                        type: "input",
                        required: true,
                        maxlength: 11,
                        placeholderText: "请输入手机号",
                        canPass: true,
                        val: ""
                    },
                    verificationCode: {
                        label: "验证码",
                        type: "input",
                        required: true,
                        maxlength: 11,
                        placeholderText: "请输入验证码",
                        canPass: true,
                        val: ""
                    },
                    keyWordName: {
                        label: "关键词",
                        type: "input",
                        required: false,
                        maxlength: 11,
                        placeholderText: "请输入关键词",
                        val: ""
                    }
                    // region: {
                    //     label: "选择地区",
                    //     type: "select",
                    //     required: true,
                    //     placeholderText: "请选择地区",
                    //     canPass: true,
                    //     val: ""
                    // }
                },
                areas: [],
                //注册成功弹窗
                dialogFlag: false,
                backCount: 3,
                backCountInterval: undefined,
            };
        },
        computed: {

        },
        created() {

        },
        mounted() {
            this.areas = areas;
        },
        watch: {

        },
        methods: {
            callLoginBox() {
                this.$refs.headerNav.openLoginDialog()
            },
            //表单校验
            formVaild() {
                let form = this.formOpt;
                let canSub = true;
                for (let key in form) {
                    if (form[key].required) {
                        if (!form[key].val) {
                            form[key].canPass = false;
                            canSub = false;
                        } else {
                            form[key].canPass = true;
                        }
                    }
                }
                this.formOpt = form;
                return canSub
            },
            //表单处理
            formFix() {
                let formOpt = this.formOpt;
                let form = {
                    "account": "",
                    "corporate": "",
                    "email": "",
                    "keyWordName": "",
                    "landline": "",
                    "name": "",
                    "password": "",
                    "phone": "",
                    // "region": "",
                    "verificationCode": ""
                }
                for (let key in form) {
                    form[key] = formOpt[key].val
                }
                return form;
            },
            //密码强度
            passCheck() {
                let password = this.formOpt.password.val;
                this.passwordLv = this.passCheckStrong(password);
            },
            //密码强度检查
            passCheckStrong(sValue) {
                var modes = 0;
                //正则表达式验证符合要求的
                if (sValue.length < 1) return modes;
                if (/\d/.test(sValue)) modes++; //数字
                if (/[a-z]/.test(sValue)) modes++; //小写
                if (/[A-Z]/.test(sValue)) modes++; //大写
                if (/\W/.test(sValue)) modes++; //特殊字符

                //逻辑处理
                switch (modes) {
                    case 1:
                        return 1;
                        break;
                    case 2:
                        return 2;
                    case 3:
                    case 4:
                        return sValue.length < 12 ? 3 : 4
                        break;
                }
            },
            //手机号检测
            phoneCheck(phones) {
                var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (!myreg.test(phones)) {
                    return false;
                } else {
                    return true;
                }
            },
            //发送验证码
            btn_send() {
                let that = this;
                if (this.phoneCheck(this.formOpt.phone.val)) {
                    if (this.interval === undefined) {
                        sendCode(null, {
                            phone: this.formOpt.phone.val
                        }).then(res => {
                            this.$message.success("发送验证码成功")
                            this.sendBtn = 60;
                            this.interval = setInterval(function () {
                                that.sendBtn--;
                                if (that.sendBtn == 0) {
                                    clearInterval(that.interval);
                                    that.interval = undefined;
                                    that.sendBtn = "发送验证码"
                                }
                            }, 1000)
                        })
                    }
                } else {
                    this.$message.error("请输入正确格式的手机号")
                }
            },
            //返回首页
            goIndex() {
                clearInterval(this.backCountInterval)
                this.backCountInterval = undefined;
                this.$router.push({
                    name: "Index"
                })
            },
            //注册
            btn_submit() {
                let that = this;
                if (this.formVaild()) {
                    let form = this.formFix()
                    if (form.password.length < 6) {
                        this.$message.error("设定密码不可少于6位");
                        return false
                    } else {
                        //处理关键字
                        form.keyWordName = form.keyWordName.replace("；", ";")
                        register(form).then(res => {
                            if (res.code == 200) {
                                this.dialogFlag = true;
                                this.backCountInterval = setInterval(function () {
                                    that.backCount--
                                    if (that.backCount == 0) {
                                        that.goIndex()
                                    }
                                }, 1000)
                            }

                        })
                    }

                }
            },
            //查看用户协议
            viewRule() {
                let routeUrl = this.$router.resolve({
                    path: "/richText",
                    query: {
                        type: "rules",
                        richTextType: "0"
                    }
                });
                window.open(routeUrl.href, '_blank');
            }

        },
        components: {
            HeaderNav
        },
    };
</script>
